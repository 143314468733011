import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";

const EditProductModal = ({ show, handleClose, productData, handleSave }) => {
    const [statusUpload, setStatusUpload] = useState(false);
    const [formData, setFormData] = useState({
        productname: "",
        description: "",
        category: { id: "", name: "" },
        subcategory: { id: "", name: "" },
        unitprice: "",
        rentalType: "hourly",
        rentalperiod: 1,
        quantity: 1,
        productImages: [],
        id: ""
    });

    useEffect(() => {
        if (productData) {
            setFormData({
                productname: productData.productname,
                description: productData.description,
                category: productData.category,
                subcategory: productData.subcategory,
                unitprice: productData.unitprice,
                rentalType: productData.rentalType || "hourly",
                rentalperiod: productData.rentalperiod || 1,
                quantity: productData.quantity || 1,
                productImages: productData.productImages,
                id: productData.id
            });
        }
    }, [productData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleImageChange = (e) => {
        setStatusUpload(true);
        const files = Array.from(e.target.files || []);
        setFormData({
            ...formData,
            productImages: files,
        });
    };

    const updateRentalPeriod = (value) => {
        setFormData(prev => ({
            ...prev,
            rentalperiod: Math.max(1, prev.rentalperiod + value) // Ensure rentalperiod >= 1
        }));
    };

    const updateQuantity = (value) => {
        setFormData(prev => ({
            ...prev,
            quantity: Math.max(1, prev.quantity + value) // Ensure quantity >= 1
        }));
    };

    const calculateTotalPrice = () => {
        const unitprice = parseFloat(formData.unitprice) || 0;
        const rentalperiod = formData.rentalperiod || 1;
        const quantity = formData.quantity || 1;
        return unitprice * rentalperiod * quantity;
    };

    const handleSubmit = () => {
        const dataToSend = { ...formData };

        // Only add productImages to dataToSend if they contain files
        if (statusUpload === false) {
            delete dataToSend.productImages;
        }

        handleSave(dataToSend);
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formProductName">
                        <Form.Label>Product Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="productname"
                            value={formData.productname}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="formDescription">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="description"
                            rows={3}
                            value={formData.description}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    {/* Price Information */}
                    <Form.Group controlId="formProductPrice">
                        <Form.Label>Product Price (₹)</Form.Label>
                        <Form.Control
                            type="number"
                            name="unitprice"
                            value={formData.unitprice}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="formRentalType">
                        <Form.Label>Rental Type</Form.Label>
                        <Form.Control
                            as="select"
                            name="rentalType"
                            value={formData.rentalType}
                            onChange={handleChange}
                        >
                            <option value="hourly">Hourly</option>
                            <option value="day">Day</option>
                            <option value="month">Month</option>
                        </Form.Control>
                    </Form.Group>

                    <Row>
                        <Col>
                            <Form.Group controlId="formRentalPeriod">
                                <Form.Label>Rental Period</Form.Label>
                                <div className="d-flex align-items-center">
                                    <Button
                                        variant="outline-secondary"
                                        onClick={() => updateRentalPeriod(-1)}
                                    >
                                        -
                                    </Button>
                                    <Form.Control
                                        type="number"
                                        name="rentalperiod"
                                        value={formData.rentalperiod}
                                        onChange={handleChange}
                                        className="mx-2"
                                        readOnly
                                    />
                                    <Button
                                        variant="outline-secondary"
                                        onClick={() => updateRentalPeriod(1)}
                                    >
                                        +
                                    </Button>
                                </div>
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group controlId="formQuantity">
                                <Form.Label>Quantity</Form.Label>
                                <div className="d-flex align-items-center">
                                    <Button
                                        variant="outline-secondary"
                                        onClick={() => updateQuantity(-1)}
                                    >
                                        -
                                    </Button>
                                    <Form.Control
                                        type="number"
                                        name="quantity"
                                        value={formData.quantity}
                                        onChange={handleChange}
                                        className="mx-2"
                                        readOnly
                                    />
                                    <Button
                                        variant="outline-secondary"
                                        onClick={() => updateQuantity(1)}
                                    >
                                        +
                                    </Button>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group controlId="formTotalPrice">
                        <Form.Label>Total Rental Price</Form.Label>
                        <Form.Control
                            type="text"
                            value={`₹${calculateTotalPrice()}`}
                            readOnly
                        />
                    </Form.Group>

                    {/* Product Images */}
                    <Form.Group controlId="formProductImages">
                        <Form.Label>Product Images</Form.Label>
                        <Form.Control
                            type="file"
                            name="productImages"
                            multiple
                            onChange={handleImageChange}
                        />
                        <div className="mt-2">
                            {formData.productImages && formData.productImages.length > 0 && (
                                <div>
                                    <p>Current Images:</p>
                                    {formData.productImages.map((image, index) => (
                                        <img
                                            key={index}
                                            src={
                                                typeof image === "string"
                                                    ? `${process.env.REACT_APP_S3_URL}${image}`
                                                    : URL.createObjectURL(image)
                                            }
                                            alt="Product"
                                            style={{
                                                width: "40px",
                                                height: "30px",
                                                objectFit: "cover",
                                                marginRight: "5px",
                                            }}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className="edit-modal">
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit} className="btnPrimary edit-button">
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditProductModal;
