import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import EditCareerModal from "./EditCareer"; // Replace with your Edit Career Modal component
import DeleteCareerModal from "./DeleteCareerModal"; // Replace with your Delete Career Modal component
import ReactPaginate from "react-paginate";
import { CAREER_LIST, DELETE_CAREER } from "../../service/blogApi";

const CareerList = () => {
  const navigate = useNavigate();
  const [careerList, setCareerList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCareer, setSelectedCareer] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleEditClick = (id) => {
    navigate(`/dashboard/edit-career/${id}`);
  };

  const handleDeleteClick = (career) => {
    setSelectedCareer(career);
    setShowDeleteModal(true);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setSelectedCareer(null);
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
    setSelectedCareer(null);
  };

  const handleDeleteCareer = async () => {
    try {
      const { data } = await DELETE_CAREER({ id: selectedCareer.id });
      if (data.code === 1) {
        toast.success(data.message);
        getCareerList(currentPage);
        handleDeleteModalClose();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message || "An error occurred. Please try again.");
    }
  };

  const getCareerList = async (page) => {
    setLoading(true);
    try {
      const { data } = await CAREER_LIST({ page });
      console.log("data-----", data)
      setCareerList(data.result.careers);
      setTotalPages(data.result.pagination.totalPages);
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        toast.error(data.message);
      } else {
        toast.error(error.message || "An error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCareerList(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container p-4">
      <table className="table table-bordered table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Job Title</th>
            <th scope="col">Experience Required</th>
            <th scope="col">Job Field</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5" className="text-center">
                Loading...
              </td>
            </tr>
          ) : careerList?.length > 0 ? (
            careerList.map((career, index) => (
              <tr key={career.id}>
                <td>{(currentPage - 1) * careerList.length + (index + 1)}</td>
                <td>{career.title}</td>
                <td>{career.experience} years</td>
                <td>{career.jobfield}</td>
                <td>
                  <button
                    className="btn btn-primary btnPrimary btn-sm"
                    onClick={() => handleEditClick(career.id)}
                    style={{ marginRight: "5px" }}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleDeleteClick(career)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">
                No careers found.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Centered Pagination */}
      {totalPages > 1 && (
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(e) => handlePageChange(e.selected + 1)}
          containerClassName={"pagination justify-content-center"}
          activeClassName={"active"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakLinkClassName={"page-link"}
        />
      )}

      {selectedCareer && (
        <DeleteCareerModal
          show={showDeleteModal}
          handleClose={handleDeleteModalClose}
          handleDelete={handleDeleteCareer}
        />
      )}
    </div>
  );
};

export default CareerList;
