import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { BLOG_CONTENT_LIST } from "../../service/blogApi";
import { useNavigate } from "react-router-dom";

const BlogContentList = () => {
    const navigate = useNavigate()
	const [blogList, setContentList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [loading, setLoading] = useState(true);


	const getBlogContentList = async (page) => {
		setLoading(true);
		try {
			const { data } = await BLOG_CONTENT_LIST({ page });
			setContentList(data.result.blogContent);
			setTotalPages(data.result.pagination.totalPages);
		} catch (error) {
			if (error.response) {
				const { data } = error.response;
				toast.error(data.message);
			} else {
				toast.error(error.message || "An error occurred. Please try again.");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getBlogContentList(currentPage);
	}, [currentPage]);

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	return (
		<div className="container p-4">
			<table className="table table-bordered table-striped table-hover">
				<thead>
					<tr>
						<th scope="col">#</th>
						<th scope="col">Content Type</th>
                        <th>Action</th>
					</tr>
				</thead>
				<tbody>
					{loading ? (
						<tr>
							<td colSpan="5" className="text-center">
								Loading...
							</td>
						</tr>
					) : blogList.length > 0 ? (
						blogList.map((blog, index) => (
							<tr key={blog.id}>
								<td>{(currentPage - 1) * blogList.length + (index + 1)}</td>
								<td>{blog.title}</td>
                                <td>
									<button
										className="btn btn-primary btn-sm"
										style={{ marginRight: "5px" }}
                                        onClick={() => navigate(`/dashboard/edit-content/${blog.blogid}`)}
									>
										Edit
									</button>
									<button
										className="btn btn-danger btn-sm"
									>
										Delete
									</button>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="5" className="text-center">
								No blogs found.
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{/* Centered Pagination */}
			{totalPages > 1 && (
				<ReactPaginate
					previousLabel={"Previous"}
					nextLabel={"Next"}
					breakLabel={"..."}
					breakClassName={"break-me"}
					pageCount={totalPages}
					marginPagesDisplayed={2}
					pageRangeDisplayed={5}
					onPageChange={(e) => handlePageChange(e.selected + 1)}
					containerClassName={"pagination justify-content-center"}
					activeClassName={"active"}
					pageClassName={"page-item"}
					pageLinkClassName={"page-link"}
					previousClassName={"page-item"}
					previousLinkClassName={"page-link"}
					nextClassName={"page-item"}
					nextLinkClassName={"page-link"}
					breakLinkClassName={"page-link"}
				/>
			)}
		</div>
	);
};

export default BlogContentList;
