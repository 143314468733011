import { useNavigate } from 'react-router-dom';
import React, { useState } from "react";
import QuillImageResize from "quill-image-resize-module-react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { CREATE_CAREER } from "../../service/blogApi"; // Import your API service

// Set up Quill
const Font = Quill.import("formats/font");
Font.whitelist = ["arial", "courier", "times-new-roman", "verdana"];
Quill.register(Font, true);
Quill.register("modules/imageResize", QuillImageResize);

const AddCareer = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [title, settitle] = useState("");
  const [experience, setExperience] = useState("");
  const [jobfield, setJobField] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (content) => {
    setValue(content); // Save the editor's content
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const payload = {
        title,
        experience,
        jobfield,
        content: value,
      };

      const { data } = await CREATE_CAREER(payload); // Call your API service
      const { code, message } = data;

      if (code === 0) {
        toast.error(message);
        setIsLoading(false);
        return;
      }

      toast.success(message);
      navigate("/dashboard/career-list"); // Redirect to the career list page
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        toast.error(data.message || "An error occurred. Please try again.");
      } else {
        toast.error(error.message || "An error occurred. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"],
      [{ script: "sub" }, { script: "super" }],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ color: [] }, { background: [] }],
      ["link", "image"],
      ["code-block"],
      ["clean"],
    ],
    imageResize: {
      modules: ["Resize", "DisplaySize"],
    },
  };

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "script",
    "align",
    "list",
    "indent",
    "color",
    "background",
    "link",
    "image",
    "code-block",
  ];

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-10">
          <div className="form_container mt-4 mb-3">
            <div className="career card">
              <div className="card-body">
                <h3 className="card-title text-center">Add Career</h3>
                <div className="form-group">
                  <label htmlFor="title">Job Title</label>
                  <input
                    type="text"
                    id="title"
                    className="form-control mb-3"
                    placeholder="Enter Job Title"
                    value={title}
                    onChange={(e) => settitle(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="experience">Experience Required</label>
                  <input
                    type="number"
                    id="experience"
                    className="form-control mb-3"
                    placeholder="Enter Experience (in years)"
                    value={experience}
                    onChange={(e) => setExperience(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="jobField">Job Field</label>
                  <input
                    type="text"
                    id="jobField"
                    className="form-control mb-3"
                    placeholder="Enter Job Field"
                    value={jobfield}
                    onChange={(e) => setJobField(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="description">Job Description</label>
                  <ReactQuill
                    theme="snow"
                    value={value}
                    onChange={handleChange}
                    placeholder="Write something here..."
                    modules={modules}
                    formats={formats}
                  />
                  <p>Preview Content:</p>
                  <div dangerouslySetInnerHTML={{ __html: value }} />
                </div>

                <div className="text-end mt-4">
                  <button
                    className="btn btn-primary btnPrimary"
                    onClick={handleSubmit}
                    disabled={isLoading}
                  >
                    {isLoading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCareer;