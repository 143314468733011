import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import "./../category/CategoryList.scss";
import { DELETE_FAQ_CATEGORY, FAQ_CATEGORY_LIST, UPDATE_FAQ_CATEGORY } from "../../service/faqApi";
import EditFaqCategoryModal from "./EditFaqCategoryModal";
import DeleteFaqCategoryModal from "./DeleteFaqCategoryModal";

const FaqCategoryList = () => {
	const { REACT_APP_S3_URL } = process.env;
	const [faqCategoryList, setFaqCategoryList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedFaqCategory, setselectedFaqCategory] = useState(null);
	const [loading, setLoading] = useState(true);

	const handleEditClick = (category) => {
		setselectedFaqCategory(category);
		setShowEditModal(true);
	};

	const handleDeleteClick = (category) => {
		setselectedFaqCategory(category);
		setShowDeleteModal(true);
	};

	const handleEditModalClose = () => {
		setShowEditModal(false);
		setselectedFaqCategory(null);
	};

	const handleDeleteModalClose = () => {
		setShowDeleteModal(false);
		setselectedFaqCategory(null);
	};

	const handleSaveChanges = async (updateFaqCategory) => {
		try {
			const formData = new FormData();
			formData.append("id", updateFaqCategory.id);
			formData.append("name", updateFaqCategory.name);
			formData.append("description", updateFaqCategory.description);
			if (updateFaqCategory.icon) {
				formData.append("icon", updateFaqCategory.icon);
			}
			const { data } = await UPDATE_FAQ_CATEGORY(formData);
			if (data.code === 1) {
				toast.success(data.message);
				getFaqCategoryList(currentPage);
				setShowEditModal(false);
			} else {
				toast.error(data.message);
			}
		} catch (error) {
            if (error.response) {
				const { data } = error.response;
				toast.error(data.message);
			} else {
                if (error.response) {
                    const { data } = error.response;
                    toast.error(data.message);
                } else {
                    toast.error(error.message || "An error occurred. Please try again.");
                }
			}
		}
	};

	const handleDeleteCategory = async () => {
		try {
			const { data } = await DELETE_FAQ_CATEGORY({ id: selectedFaqCategory.id });
			if (data.code === 1) {
				toast.success(data.message);
				getFaqCategoryList(currentPage);
				handleDeleteModalClose();
			} else {
				toast.error(data.message);
			}
		} catch (error) {
            if (error.response) {
				const { data } = error.response;
				toast.error(data.message);
			} else {
				toast.error(error.message || "An error occurred. Please try again.");
			}
		}
	};

	const getFaqCategoryList = async (page) => {
		setLoading(true);
		try {
			const { data } = await FAQ_CATEGORY_LIST({ page });
			setFaqCategoryList(data.result.faqCategories);
			setTotalPages(data.result.pagination.totalPages);
		} catch (error) {
			if (error.response) {
				const { data } = error.response;
				toast.error(data.message);
			} else {
				toast.error(error.message || "An error occurred. Please try again.");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getFaqCategoryList(currentPage);
	}, [currentPage]);

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	return (
		<div className="container p-4">
			<table className="table table-bordered table-striped table-hover">
				<thead>
					<tr>
						<th scope="col">#</th>
						<th scope="col">Category</th>
						<th scope="col">Image</th>
						<th scope="col">Action</th>
					</tr>
				</thead>
				<tbody>
					{loading ? (
						<tr>
							<td colSpan="6" className="text-center">
								Loading...
							</td>
						</tr>
					) : faqCategoryList.length > 0 ? (
						faqCategoryList.map((category, index) => (
							<tr key={category.id}>
								<td>
									{(currentPage - 1) * faqCategoryList.length + (index + 1)}
								</td>
								<td>{category.name}</td>
								<td>
									<img
										src={REACT_APP_S3_URL + category.icon}
										alt={category.icon}
										style={{
											width: "40px",
											height: "30px",
											objectFit: "cover",
										}}
									/>
								</td>
								<td>
									<button
										className="btn btn-primary btn-sm btnPrimary"
										onClick={() => handleEditClick(category)}
										style={{ marginRight: "5px" }}
									>
										Edit
									</button>
									<button
										className="btn btn-danger btn-sm"
										onClick={() => handleDeleteClick(category)}
									>
										Delete
									</button>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="6" className="text-center">
								No sub categories found.
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{/* Centered Pagination */}
			{totalPages > 1 && (
				<ReactPaginate
					previousLabel={"Previous"}
					nextLabel={"Next"}
					breakLabel={"..."}
					breakClassName={"break-me"}
					pageCount={totalPages}
					marginPagesDisplayed={2}
					pageRangeDisplayed={5}
					onPageChange={(e) => handlePageChange(e.selected + 1)}
					containerClassName={"pagination justify-content-center"}
					activeClassName={"active"}
					pageClassName={"page-item"}
					pageLinkClassName={"page-link"}
					previousClassName={"page-item"}
					previousLinkClassName={"page-link"}
					nextClassName={"page-item"}
					nextLinkClassName={"page-link"}
					breakLinkClassName={"page-link"}
				/>
			)}

			{/* Render the edit modal */}
			{selectedFaqCategory && (
				<EditFaqCategoryModal
					show={showEditModal}
					handleClose={handleEditModalClose}
					faqCategoryData={selectedFaqCategory}
					handleSave={handleSaveChanges}
				/>
			)}

			{/* Render the delete modal */}
			{selectedFaqCategory && (
				<DeleteFaqCategoryModal
					show={showDeleteModal}
					handleClose={handleDeleteModalClose}
					handleDelete={handleDeleteCategory}
				/>
			)}
		</div>
	);
};

export default FaqCategoryList;
