import React from 'react'
import {Modal, Button, Spinner} from "react-bootstrap";

const BlockCustomerModal = ({ show, handleClose, handleBlock, status, loading }) => {

  return (
    <>
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{status ? "Unblock Customer" : "Block Customer"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{status
            ? "Are you sure you want to unblock block this customer?"
            : "Are you sure you want to block this customer?"}
            </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={loading}>
            Cancel
          </Button>
          <Button 
          style={{ backgroundColor: "#0c6c6a",
            color: "#fff"
           }}
            onClick={handleBlock}
            disabled={loading} 
            >
            {loading && <Spinner animation="border" size="sm" />}
            {status ? "Unblock" : "Block"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default BlockCustomerModal