import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { REPLY_TO_CUTOMER_QUERY } from "../../service/userApi";
import { toast } from "react-toastify";

const ReplyQueriesModal = ({ show, handleClose,queryData }) => {
  const [replyMessage, setReplyMessage] = useState("");
    const [loader,setLoading] = useState(false)
  const handleSendReply = async(e) => {
    try {
        e.preventDefault();
        setLoading(true)
        const { data } = await REPLY_TO_CUTOMER_QUERY({
            email: queryData.email,
            message: replyMessage
        })
        const {code} = data || {}
        if (code === 1) {
            toast.success("Reply sent successfully")
        }
        setReplyMessage(""); 
        handleClose(); 
        setLoading(false)
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            toast.error(data.message);
        } else {
            toast.error(error.message || "An error occurred. Please try again.");
        }
    }
   
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Reply to Customer Query</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Reply Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            value={replyMessage}
            onChange={(e) => setReplyMessage(e.target.value)}
            placeholder="Write your reply here..."
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          className="btn btn-success buttonSuccess"
          onClick={handleSendReply}
          disabled={loader} // Disable button if message is empty
        >
          {loader ? "Sending..." : "Send Reply"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReplyQueriesModal;
