import { adminServiceApiCall } from "./adminService";
import { CONSTANT_URL } from "./apiEndPoint";
import { userServiceApiCall } from "./userService";
export const CREATE_FAQ_CATEGORY = async (payload) => {
    return await adminServiceApiCall("post", CONSTANT_URL.CREATE_FAQ_CATEGORY, payload)
}
export const UPDATE_FAQ_CATEGORY = async (payload) => {
    return await adminServiceApiCall("post", CONSTANT_URL.UPDATE_FAQ_CATEGORY, payload)
}
export const FAQ_CATEGORY_LIST = async (payload) => {
    const page=`?page=${payload.page}`
    return await userServiceApiCall("get", CONSTANT_URL.FAQ_CATEGORY_LIST + page)
}
export const DELETE_FAQ_CATEGORY = async (payload) => {
    return await adminServiceApiCall("delete", CONSTANT_URL.DELETE_FAQ_CATEGORY, payload)
}
export const CREATE_FAQ = async (payload) => {
    return await adminServiceApiCall("post", CONSTANT_URL.CREATE_FAQ, payload)
}
export const UPDATE_FAQ = async (payload) => {
    return await adminServiceApiCall("post", CONSTANT_URL.UPDATE_FAQ, payload)
}
export const FAQ_LIST = async (payload) => {
    const page=`?page=${payload.page}`
    return await adminServiceApiCall("get", CONSTANT_URL.FAQ_LIST + page)
}
export const DELETE_FAQ = async (payload) => {
    return await adminServiceApiCall("delete", CONSTANT_URL.DELETE_FAQ, payload)
}
export const GET_ALL_CUSTOMER_QUERY = async (payload) => {
    const page=`?page=${payload.page}`
    return await adminServiceApiCall("get", CONSTANT_URL.GET_ALL_CUSTOMER_QUERY + page)
}
export const GET_FAQ_DETAILS = async (payload) => {
    const query=`?id=${payload.id}`
    return await userServiceApiCall("get", CONSTANT_URL.FAQ_DETAILS + query)
}

