import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DeleteBlogModal from "./DeleteBlogModal"; // Replace with your Delete Blog Modal
import ReactPaginate from "react-paginate";
import { BLOG_LIST, DELETE_BLOG } from "../../service/blogApi";

const ListBlogs = () => {
	const navigate = useNavigate();
	const { REACT_APP_S3_URL } = process.env;
	const [blogList, setBlogList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedBlog, setSelectedBlog] = useState(null);
	const [loading, setLoading] = useState(true);

	const handleEditClick = (id) => {
		navigate(`/dashboard/edit-blog/${id}`);
	};

	const handleDeleteClick = (blog) => {
		setSelectedBlog(blog);
		setShowDeleteModal(true);
	};

	const handleEditModalClose = () => {
		setShowEditModal(false);
		setSelectedBlog(null);
	};

	const handleDeleteModalClose = () => {
		setShowDeleteModal(false);
		setSelectedBlog(null);
	};



	const handleDeleteBlog = async () => {
		try {
			const { data } = await DELETE_BLOG({ id: selectedBlog.id });
			if (data.code === 1) {
				toast.success(data.message);
				getBlogList(currentPage);
				handleDeleteModalClose();
			} else {
				toast.error(data.message);
			}
		} catch (error) {
			toast.error(error.message || "An error occurred. Please try again.");
		}
	};

	const getBlogList = async (page) => {
		setLoading(true);
		try {
			const { data } = await BLOG_LIST({ page });
            console.log("data",data);
			setBlogList(data.result.blogs);
			setTotalPages(data.result.pagination.totalPages);
		} catch (error) {
			if (error.response) {
				const { data } = error.response;
				toast.error(data.message);
			} else {
				toast.error(error.message || "An error occurred. Please try again.");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getBlogList(currentPage);
	}, [currentPage]);

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	return (
		<div className="container p-4">
			<table className="table table-bordered table-striped table-hover">
				<thead>
					<tr>
						<th scope="col">#</th>
						<th scope="col">Title</th>
						<th scope="col">Image</th>
						<th scope="col">Action</th>
					</tr>
				</thead>
				<tbody>
					{loading ? (
						<tr>
							<td colSpan="5" className="text-center">
								Loading...
							</td>
						</tr>
					) : blogList.length > 0 ? (
						blogList.map((blog, index) => (
							<tr key={blog.id}>
								<td>{(currentPage - 1) * blogList.length + (index + 1)}</td>
								<td>{blog.title}</td>
								<td>
									<img
										src={REACT_APP_S3_URL + blog.blogimage[0]}
										alt={blog.title}
										style={{
											width: "40px",
											height: "30px",
											objectFit: "cover",
										}}
									/>
								</td>
								<td>
									<button
										className="btn btn-primary btnPrimary btn-sm"
										onClick={() => handleEditClick(blog.id)}
										style={{ marginRight: "5px" }}
									>
										Edit
									</button>
									<button
										className="btn btn-danger btn-sm"
										onClick={() => handleDeleteClick(blog)}
									>
										Delete
									</button>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="5" className="text-center">
								No blogs found.
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{/* Centered Pagination */}
			{totalPages > 1 && (
				<ReactPaginate
					previousLabel={"Previous"}
					nextLabel={"Next"}
					breakLabel={"..."}
					breakClassName={"break-me"}
					pageCount={totalPages}
					marginPagesDisplayed={2}
					pageRangeDisplayed={5}
					onPageChange={(e) => handlePageChange(e.selected + 1)}
					containerClassName={"pagination justify-content-center"}
					activeClassName={"active"}
					pageClassName={"page-item"}
					pageLinkClassName={"page-link"}
					previousClassName={"page-item"}
					previousLinkClassName={"page-link"}
					nextClassName={"page-item"}
					nextLinkClassName={"page-link"}
					breakLinkClassName={"page-link"}
				/>
			)}

			{selectedBlog && (
				<DeleteBlogModal
					show={showDeleteModal}
					handleClose={handleDeleteModalClose}
					handleDelete={handleDeleteBlog}
				/>
			)}
		</div>
	);
};

export default ListBlogs;
