import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

const BlockPartnerModal = ({
  show,
  handleClose,
  handleBlock,
  status,
  loading,
}) => {
  // console.log("Status in Modal:", status);

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {status ? "Unblock Partner" : "Block Partner"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {status
            ? "Are you sure you want to unblock this partner?"
            : "Are you sure you want to block this partner?"}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#0c6c6a",
              color: "#fff",
            }}
            onClick={handleBlock}
            disabled={loading}
          >
            {loading && <Spinner animation="border" size="sm" />}
            {status ? "Unblock" : "Block"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BlockPartnerModal;
