import React, { useEffect } from "react";
import {
	Navigate,
	Outlet,
	Route,
	Routes,
	useLocation,
	useNavigate,
} from "react-router-dom";
import Authentication from "../pages/auth/index";
import DashboardHeader from "../components/dashboardHeader/DashboardHeader";
import Dashboard from "../pages/dashboard/Dashboard";
import SideBar from "../components/sideBar/SideBar";
import "../pages/dashboard/Dashboard.css";
import CategoryList from "../pages/category/CategoryList";
import AddCategory from "../pages/category/AddCategory";
import ProductList from "../pages/product/ProductList.jsx";
import AddProduct from "../pages/product/AddProduct";
import Customer from "../pages/user/Customer";
import Partner from "../pages/user/Partner";
import ResetPassword from "../pages/auth/resetPassword/ResetPassword";
import Policy from "../pages/Policy";
import Settings from "../pages/Settings";
import ChangePassword from "../pages/profile/ChangePassword";
import { getToken } from "../util/validator";
import { toast } from "react-toastify";
import OTP from "../pages/profile/OTP";
import VerifyOTP from "../pages/profile/VerifyOTP";
import AddSubCategory from "../pages/subCategory/AddSubCategory.jsx";
import SubCategoryList from "../pages/subCategory/SubCategoryList";
import AddCareer from "../pages/career/AddCareer.jsx";
import CareerList from "../pages/career/CareerList.jsx";
import ListBlogs from "../pages/blog/ListBlogs.jsx";
import BlogContentList from "../pages/blog/BlogContentList.jsx";
import EditCareer from "../pages/career/EditCareer.jsx";
import EditBlog from "../pages/blog/EditBlog.jsx";
import CreateBlogs from "../pages/blog/CreateBlogs.jsx";
import CreateFaqCategory from "../pages/helpCenter/CreateFaqCategory.jsx";
import FaqCategoryList from "../pages/helpCenter/FaqCategoryList.jsx";
import CreateFaq from "../pages/helpCenter/CreateFaq.jsx";
import FaqList from "../pages/helpCenter/FaqList.jsx";
import CustomerQueries from "../pages/helpCenter/CustomerQueries.jsx";
import JobApplications from "../pages/career/JobApplications.jsx";


const Routing = () => {
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		function adjustDashboardHeight() {
			const dashboardContent =
				document.getElementsByClassName("dashboard_content")[0];
			const sidebar = document.getElementById("sidebar");
			const header = document.getElementById("dashboard-header");

			if (!dashboardContent || !sidebar || !header) return;

			dashboardContent.style.height = `${
				sidebar.offsetHeight - header.offsetHeight
			}px`;
		}

		adjustDashboardHeight(); // Initial adjustment on load

		const sidebarElement = document.getElementById("sidebar");

		if (sidebarElement) {
			const observer = new MutationObserver(() => {
				adjustDashboardHeight();
			});

			// Observe all dropdown elements for class changes
			const dropdowns = sidebarElement.querySelectorAll(".dropdown");
			dropdowns.forEach((dropdown) => {
				observer.observe(dropdown, {
					attributes: true, // Watch for attribute changes
					attributeFilter: ["class"], // Specifically watch the class attribute
				});
			});

			// Also adjust height on window resize
			window.addEventListener("resize", adjustDashboardHeight);

			// Cleanup event listeners and observer on unmount
			return () => {
				window.removeEventListener("resize", adjustDashboardHeight);
				observer.disconnect();
			};
		}
	}, [location]);

	const DashboardRoutes = () => {
		return (
			<>
				<div className="main_body">
					<SideBar />
					<div className="main_content">
						<DashboardHeader />

						<div className="dashboard_content" style={{ overflowY: "scroll" }}>
							<Outlet />
						</div>
					</div>
				</div>
			</>
		);
	};

	const ProtectedRoute = ({ element }) => {
		const isLoggedIn = getToken();
		if (!isLoggedIn) {
			toast.error("Session Expired");
			return <Navigate to="/" />;
		}
		return element;
	};

	useEffect(() => {
		const isLoggedIn = getToken();
		const { pathname } = location;
		if (isLoggedIn && pathname === "/") {
			navigate("/dashboard");
		}
	}, [location, navigate]);

	return (
		<Routes>
			<Route path="/" element={<Authentication />} />
			<Route path="/admin/reset-password/:token" element={<ResetPassword />} />
			<Route
				path="/dashboard"
				element={<ProtectedRoute element={<DashboardRoutes />} />}
			>
				<Route index element={<Dashboard />} />
				<Route index path="category-list" element={<CategoryList />} />
				<Route path="add-category" element={<AddCategory />} />
				<Route path="add-subcategory" element={<AddSubCategory />} />
				<Route path="subcategory-list" element={<SubCategoryList />} />
				<Route path="create-blog" element={<CreateBlogs />} />
				<Route path="blog-list" element={<ListBlogs />} />
				<Route path="product-list" element={<ProductList />} />
				<Route path="add-product" element={<AddProduct />} />
				<Route path="customer" element={<Customer />} />
				<Route path="partner" element={<Partner />} />
				<Route path="policy" element={<Policy />} />
				<Route path="settings" element={<Settings />} />
				<Route path="send-otp" element={<OTP />} />
				<Route path="verify-otp" element={<VerifyOTP />} />
				<Route path="change-password" element={<ChangePassword />} />
				<Route path="add-career" element={<AddCareer />} />
				<Route path="create-faq-category" element={<CreateFaqCategory />}/>
				<Route path="faq-category-list" element={<FaqCategoryList/>} />
				<Route path="create-faq" element={<CreateFaq/>} />
				<Route path="faq-list" element={<FaqList/>} />
				<Route path="career-list" element={<CareerList />} />
				<Route path="customer-queries" element={<CustomerQueries />} />
				<Route path="edit-career/:id" element={<EditCareer />} />
				<Route path="blog-content-list" element={<BlogContentList/>} />
				<Route path="edit-blog/:blogid" element={<EditBlog/>} />
				<Route path="job-application" element={<JobApplications/>}/>
			</Route>
		</Routes>
	);
};

export default Routing;
