import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ImageUploader from "react-images-upload";
import { CREATE_FAQ_CATEGORY } from "../../service/faqApi";

const CreateFaqCategory = () => {
	const navigate = useNavigate();
	const [iconImage, setIconImage] = useState([]);

	const onDrop = (pictureFiles) => {
		setIconImage(pictureFiles);
	};

	const [formData, setFormData] = useState({
		description: "",
		name: "",
	});

	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");

	const handleChange = (event) => {
		const { name, value } = event.target;

		if (name === "name" && /^[a-z]/.test(value)) {
			setError(
				"Sub category name is case sensitive and should start with an uppercase letter."
			);
		} else {
			setError("");
		}

		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setIsLoading(true);

		try {
			const formDataPayload = new FormData();
			formDataPayload.append("name", formData.name);
			formDataPayload.append("description", formData.description);
			iconImage.forEach((image) => {
				formDataPayload.append("icon", image);
			});
			const { data } = await CREATE_FAQ_CATEGORY(formDataPayload);
			const { code, message } = data;
			if (code === 0) {
				toast.error(message);
				setIsLoading(false);
				return;
			}
			toast.success(message);
			navigate("/dashboard/faq-category-list");
		} catch (error) {
			if (error.response) {
				const { status, data } = error.response;
				if (status === 409) {
					toast.error(data.message || "A conflict occurred. Please try again.");
				} else {
					toast.error(data.message || "An error occurred. Please try again.");
				}
			} else {
				toast.error(error.message || "An error occurred. Please try again.");
			}
			setIsLoading(false);
		}
	};

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-10">
					<div className="form_container mt-4 mb-4">
						<div className="card">
							<div className="card-body">
								<h3 className="card-title text-center">Create FAQ Category</h3>
								<form onSubmit={handleSubmit}>
									<div className="form-group row">
										<label
											htmlFor="category-name"
											className="col-sm-4 col-form-label"
										>
											FAQ Category Name:
										</label>
										<div className="col-sm-8">
											<input
												type="text"
												className="form-control"
												id="category-name"
												name="name"
												placeholder="Enter FAQ category name"
												value={formData.name}
												onChange={handleChange}
												required
												disabled={isLoading}
											/>
											{error && <small className="text-danger">{error}</small>}
										</div>
									</div>

									<div className="form-group row">
										<label htmlFor="image" className="col-sm-4 col-form-label">
											Upload Image:
										</label>
										<div className="col-sm-8">
											<ImageUploader
												withIcon={true}
												buttonText="Choose Images"
												onChange={onDrop}
												imgExtension={[".jpg", ".jpeg", ".svg",".png"]}
												maxFileSize={5242880}
												withPreview={true}
											/>
										</div>
									</div>
									<div className="form-group row">
										<label
											htmlFor="description"
											className="col-sm-4 col-form-label"
										>
											Description:
										</label>
										<div className="col-sm-8">
											<textarea
												type="text"
												id="description"
												name="description"
												className="form-control"
												placeholder="Describe your sub category"
												rows="6"
												value={formData.description}
												onChange={handleChange}
												required
												disabled={isLoading}
											/>
										</div>
									</div>
									<div className="form-group row mt-4">
										<div className="col-sm-4"></div>
										<div className="col-sm-8 button-group">
											<input
												type="submit"
												value={isLoading ? "Submitting..." : "Submit"}
												className="btn btn-success buttonSuccess"
												style={{ width: "150px", marginRight: "10px" }}
												disabled={isLoading}
											/>
											<input
												type="button"
												value="Cancel"
												className="btn btn-secondary"
												style={{ width: "150px" }}
												onClick={() =>
													setFormData({
														categoryName: "",
														description: "",
														productType: "",
													})
												}
												disabled={isLoading}
											/>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreateFaqCategory;
