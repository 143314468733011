import React, { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { UPDATE_CATEGORY, DELETE_CATEGORY } from "../../service/productApi";
import EditCategoryModal from "../category/EditCategoryModal";
import DeleteCategoryModal from "../category/DeleteCategoryModal";
import {BLOCK_UNBLOCK_USER} from "../../service/apiAuth"
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import debounce from "lodash.debounce";
import "./user.scss";
import BlockPartnerModal from "./BlockPartnerModal";
import { BULK_EMAIL, PARTNER_LIST, SEND_BULK_EMAIL } from "../../service/userApi";
import { Button, Modal } from "react-bootstrap";

const Partner = () => {
	const [totalCount, setTotalCount] = useState(0)
	const [isSelectAll, setIsSelectAll] = useState(false)
	const [partnerList, setPartnerList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [showEmailModal, setShowEmailModal] = useState(false);
	const [emailDetails, setEmailDetails] = useState({
		subject: "",
		message: "",
	});
	const [totalPages, setTotalPages] = useState(1);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [searchTerm, setSearchTerm] = useState("");
	const [showModal, setShowModal] = useState(false);
  	const [selectedPartner, setSelectedPartner] = useState(null);
  	const [loading, setLoading] = useState(false);

	const handleClose = () => setShowModal(false);

	const handleBlock = async () => {
		if (!selectedPartner) return;
		try {
		  setLoading(true);
		  const requestBody = {
			userId: selectedPartner.id,
			isBlock: !selectedPartner.status, 
		  };
	
		  const { data } = await BLOCK_UNBLOCK_USER(requestBody);
	
		  if (data.code === 1) {
			toast.success(data.message);
			// Update the partner list to reflect the new status
			setPartnerList((prevList) =>
				prevList.map((partner) =>
				  partner.id === selectedPartner.id
					? { ...partner, status: !selectedPartner.status } // Update status
					: partner
				)
			  );
		  } else {
			toast.error(data.message);
		  }
		} catch (error) {
		  let message = "An unexpected error occurred.";
		  if (error.response && error.response.data && error.response.data.message) {
			message = error.response.data.message;
		  } else {
			message = error.message || message;
		  }
		  toast.error(message);
		} finally {
		  setLoading(false);
		  setShowModal(false);
		  setSelectedPartner(null);
		}
	  };
	

	  const handleBlockClick = (partner) => {
		console.log("Partner to Block/Unblock:", partner); 
		setSelectedPartner(partner);
		setShowModal(true);
	  };

	const handleEditClick = (category) => {
		setSelectedCategory(category);
		setShowEditModal(true);
	};

	const handleDeleteClick = (category) => {
		setSelectedCategory(category);
		setShowDeleteModal(true);
	};

	const handleEditModalClose = () => {
		setShowEditModal(false);
		setSelectedCategory(null);
	};

	const handleDeleteModalClose = () => {
		setShowDeleteModal(false);
		setSelectedCategory(null);
	};

	const handleSaveChanges = async (updatedCategory) => {
		try {
			const payload = {
				id: updatedCategory.id,
				categoryName: updatedCategory.categoryName,
				description: updatedCategory.description,
			};
			const { data } = await UPDATE_CATEGORY(payload);
			if (data.code === 1) {
				toast.success(data.message);
				getPartnerList();
			} else {
				toast.error(data.message);
			}
		} catch (error) {
			toast.error(error.message || "An error occurred. Please try again.");
		}
	};

	const handleSelectUser = (email) => {
		setSelectedUsers((prev) =>
			prev.includes(email) ? prev.filter((id) => id !== email) : [...prev, email]
		);
	};

	const handleSelectAll = (e) => {
		if (e.target.checked) {
			setIsSelectAll(true)
			setSelectedUsers(partnerList.map((user) => user.email));
		} else {
			setIsSelectAll(false)
			setSelectedUsers([]);
		}
	};

	const handleSendBulkEmail = async () => {
		setLoading(true);
		try {
			const payload = {
				emailList: selectedUsers,
				subject: emailDetails.subject,
				body: emailDetails.message,
			};
			await BULK_EMAIL(payload);
			const {data} = await SEND_BULK_EMAIL()
			if (data.code === 1) {
				toast.success("Emails sent successfully!");
				setShowEmailModal(false);
				setSelectedUsers([]);
			} else {
				toast.error(data.message || "Failed to send emails.");
			}
		} catch (error) {
			const {response} = error || {}
			const {data} = response || {}
			const {message} = data || {}
			toast.error(message || "An unexpected error occurred.");
		}finally{
			setLoading(false)
		}
	};

	const getPartnerList = useCallback(async (page = 1, search = "") => {
		try {
			const { data } = await PARTNER_LIST({ page, search });
			setPartnerList(data.result.partners);
			setTotalCount(data.result.pagination.totalCount)
			setTotalPages(Number(data.result.pagination.totalPages));
		} catch (error) {
			if (error.response) {
				const { data } = error.response;
				toast.error(data.message);
			} else {
				toast.error(error.message || "An error occurred. Please try again.");
			}
		}
	}, []);

	// Debounce search function
	const debouncedSearch = useCallback(
		debounce((searchTerm) => {
			console.log("Debounced search term:", searchTerm);
			getPartnerList(1, searchTerm);
		}, 300),
		[getPartnerList]
	);

	const handleSearchChange = (e) => {
		setSearchTerm(e.target.value);
		setCurrentPage(1);
		debouncedSearch(e.target.value);
	};

	useEffect(() => {
		getPartnerList(currentPage);
	}, [currentPage, getPartnerList]);

	useEffect(() => {
		return () => {
			debouncedSearch.cancel();
		};
	}, [debouncedSearch]);

	const handlePageClick = (event) => {
		const selectedPage = event.selected + 1;
		setCurrentPage(selectedPage);
	};

	const maskPhoneNumber = (phoneNumber) => {
		if (phoneNumber?.length > 6) {
			return `******${phoneNumber.slice(-4)}`;
		}
		return phoneNumber;
	};

	const maskAadhaarNumber = (aadhaarNumber) => {
		if (aadhaarNumber?.length > 4) {
			return `********${aadhaarNumber.slice(-4)}`;
		}
		return aadhaarNumber;
	};

	return (
		<div className="container p-4">
			<div className="mb-3">
				<input
					type="search"
					className="form-control"
					placeholder="Search by first name, last name, email, etc."
					onChange={handleSearchChange}
					value={searchTerm}
				/>
			</div>
			{isSelectAll &&
				<div className="alert alert-info my-2 d-flex justify-content-center" role="alert">
					You have selected {totalCount} partners. Do you want to send bulk emails to all selected partners?
				</div>
			}
			<table className="table table-bordered table-striped table-hover">
				<thead>
					<tr>
						<th>
						<input
						type="checkbox"
						onChange={handleSelectAll}
						checked={selectedUsers.length === partnerList.length && partnerList.length > 0}
						/>
						</th>
						<th scope="col">#</th>
						<th scope="col">Brand Name</th>
						<th scope="col">First Name</th>
						<th scope="col">Last Name</th>
						<th scope="col">Mobile No</th>
						<th scope="col">Email</th>
						<th scope="col">Aadhaar No</th>
						<th scope="col">Date Of Birth</th>
						<th scope="col">Gender</th>
						<th scope="col">Action</th>
					</tr>
				</thead>
				<tbody>
					{partnerList?.length > 0 ? (
						partnerList.map((partner, index) => (
							<tr key={partner.id}>
								<td>
								<input
									type="checkbox"
									checked={selectedUsers.includes(partner.email)}
									onChange={() => handleSelectUser(partner.email)}
								/>
							</td>
								<td>{(currentPage - 1) * 10 + (index + 1)}</td>
								<td>{partner.brandName}</td>
								<td>{partner.firstName}</td>
								<td>{partner.lastName}</td>
								<td>{maskPhoneNumber(partner.mobileNumber)}</td>
								<td>{partner.email}</td>
								<td>{maskAadhaarNumber(partner.aadhaar)}</td>
								<td>{moment(partner.dob).format("DD-MM-YYYY")}</td>
								<td>{partner.gender}</td>
								<td>
									<div className="toggle-wrapper">
									<span className="switch-text">{partner.status ? "Unblock" : "Block"}</span>
									<label className="switch">
										<input
											type="checkbox"
											checked={partner.status}
											onChange={() => handleBlockClick(partner)}
										/>
										<span className="slider round"></span>
									</label>
								</div></td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="6" className="text-center">
								{loading ? "Loading..." : "No partners found."}
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{selectedUsers.length > 0 && (
				<Button className="mt-3 ms-auto d-block btn btn-success buttonSuccess" onClick={() => setShowEmailModal(true)}>
					Send Email
				</Button>
			)}

			{/* Centered Pagination */}
			{totalPages > 1 && (
				<ReactPaginate
					previousLabel={"Previous"}
					nextLabel={"Next"}
					breakLabel={"..."}
					breakClassName={"break-me"}
					pageCount={totalPages}
					marginPagesDisplayed={2}
					pageRangeDisplayed={5}
					onPageChange={handlePageClick}
					containerClassName={"pagination justify-content-center"}
					activeClassName={"active"}
					pageClassName={"page-item"}
					pageLinkClassName={"page-link"}
					previousClassName={"page-item"}
					previousLinkClassName={"page-link"}
					nextClassName={"page-item"}
					nextLinkClassName={"page-link"}
					breakLinkClassName={"page-link"}
				/>
			)}

			{/* Render the edit modal */}
			{selectedCategory && (
				<EditCategoryModal
					show={showEditModal}
					handleClose={handleEditModalClose}
					categoryData={selectedCategory}
					handleSave={handleSaveChanges}
				/>
			)}

			{/* Render the delete modal */}
			{selectedCategory && (
				<DeleteCategoryModal
					show={showDeleteModal}
					handleClose={handleDeleteModalClose}
					categoryData={selectedCategory}
					handleDelete={async () => {
						try {
							const { data } = await DELETE_CATEGORY(selectedCategory.id);
							if (data.code === 1) {
								toast.success(data.message);
								getPartnerList();
							} else {
								toast.error(data.message);
							}
						} catch (error) {
							toast.error(
								error.message || "An error occurred. Please try again."
							);
						}
					}}
				/>
			)}

			{/* Render the block modal */}
			{selectedPartner && (
				<BlockPartnerModal
					show={showModal}
					handleClose={handleClose}
					handleBlock={handleBlock}
					status={selectedPartner.status}
					loading={loading}
				/>
			)}
			<Modal show={showEmailModal} onHide={() => {
				setShowEmailModal(false);
				setEmailDetails({ subject: "", message: "" });
			}}>
				<Modal.Header closeButton>
					<Modal.Title>Send Bulk Email</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="mb-3">
						<label>Subject</label>
						<input
							type="text"
							className="form-control"
							value={emailDetails.subject}
							onChange={(e) =>
								setEmailDetails({ ...emailDetails, subject: e.target.value })
							}
						/>
					</div>
					<div className="mb-3">
						<label>Message</label>
						<textarea
							className="form-control"
							rows="4"
							value={emailDetails.message}
							onChange={(e) =>
								setEmailDetails({ ...emailDetails, message: e.target.value })
							}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => {
        				setShowEmailModal(false);
				        setEmailDetails({ subject: "", message: "" });
    					}}>
						Cancel
					</Button>
					<Button variant="primary" onClick={handleSendBulkEmail} className="btn btn-success buttonSuccess">
					{loading ? "Sending..." : "Send"}
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default Partner;
