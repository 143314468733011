import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useRef, useMemo, useEffect } from "react";
import QuillImageResize from "quill-image-resize-module-react"; // Import image resize module
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import AWS from "aws-sdk";
import { toast } from "react-toastify";
import {
  CAREER_DETAIL,
  CREATE_CAREER,
  UPDATE_CAREER,
} from "../../service/blogApi";

// Set up Quill
const Font = Quill.import("formats/font");
Font.whitelist = ["arial", "courier", "times-new-roman", "verdana"];
Quill.register(Font, true);
Quill.register("modules/imageResize", QuillImageResize);

const EditCareer = () => {
  const { id } = useParams();
  console.log("id in edit career page", id)
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [title, settitle] = useState("");
  const [experience, setexperience] = useState("");
  const [jobfield, setjobfield] = useState("");
  const [value, setValue] = useState("");
  const quillRef = useRef(null);

  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    region: process.env.REACT_APP_REGION,
  });

  const fetchCareerContent = async (careerHtmlUrl) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_RENTTE_CAREER_URL + careerHtmlUrl
      );
      if (response.ok) {
        const htmlContent = await response.text();
        setValue(htmlContent);
      } else {
        console.error("Failed to fetch career content");
      }
    } catch (error) {
      console.error("Error fetching career content:", error);
    }
  };

  const getCareerDetail = async (id) => {
    try {
      const { data } = await CAREER_DETAIL({ id });
      if (data.result) {
        const career = data.result.career;
        // setSelectedCareerId(career.id);
        settitle(career.title);
        setexperience(career.experience);
        setjobfield(career.jobfield);
        fetchCareerContent(career.contenturl);

      }
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        toast.error(data.message);
      } else {
        toast.error(error.message || "An error occurred. Please try again.");
      }
    }
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ font: [] }],
          [{ size: ["small", false, "large", "huge"] }],
          ["bold", "italic", "underline", "strike"],
          [{ script: "sub" }, { script: "super" }],
          [{ align: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ color: [] }, { background: [] }],
          ["link", "image"],
          ["code-block"],
          ["clean"],
        ],
      },
      imageResize: {
        modules: ["Resize", "DisplaySize"],
      },
    }),
    []
  );

  const formats = useMemo(
    () => [
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "strike",
      "script",
      "align",
      "list",
      "indent",
      "color",
      "background",
      "link",
      "image",
      "code-block",
    ],
    []
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const formDataPayload = new FormData();
      formDataPayload.append("title", title);
      formDataPayload.append("experience", experience);
      formDataPayload.append("jobfield", jobfield);
      formDataPayload.append("detail", value);
      formDataPayload.append("id", id);
      const { data } = await UPDATE_CAREER(formDataPayload);
      const { code, message } = data;
      if (code === 0) {
        toast.error(message);
        setIsLoading(false);
        return;
      }
      toast.success(message);
      navigate("/dashboard/career-list");
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        toast.error(data.message || "An error occurred. Please try again.");
      } else {
        toast.error(error.message || "An error occurred. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCareerDetail(id);
  }, []);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-10">
          <div className="form_container mt-4 mb-3">
            <div className="career card">
              <div className="card-body">
              <h3 className="card-title text-center">Edit Career</h3>

                {/* Title Input */}
                <div className="form-group">
                <label className="mb-2" htmlFor="title">Job Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    placeholder="Edit job title"
                    value={title}
                    onChange={(e) => settitle(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="experience">Experience Required</label>
                  <input
                    type="number"
                    id="experience"
                    className="form-control mb-3"
                    placeholder="Enter Experience (in years)"
                    value={experience}
                    onChange={(e) => setexperience(e.target.value)}

                  />
                </div>

                <div className="form-group">
                  <label htmlFor="jobField">Job Field</label>
                  <input
                    type="text"
                    id="jobField"
                    className="form-control mb-3"
                    placeholder="Enter Job Field"
                    value={jobfield}
                    onChange={(e) => setjobfield(e.target.value)}

                  />
                </div>

                {/* Description Input */}

                {/* ReactQuill Editor */}
                <div className="form-group">
                <label className="mb-2" htmlFor="description">Job Description</label>
                  <ReactQuill
                    ref={quillRef}
                    theme="snow"
                    value={value}
                    onChange={setValue}
                    placeholder="Write something here..."
                    modules={modules}
                    formats={formats}
                  />
                </div>

                {/* Preview */}
                <p>Preview Content:</p>
                <div dangerouslySetInnerHTML={{ __html: value }} />

                {/* Publish Button */}
                {value && (
                  <div className="text-end">
                    <button
                      className="btn btn-primary btnPrimary mr-5"
                      onClick={handleSubmit}
                    >
                      {isLoading ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCareer;
