import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CREATE_FAQ, FAQ_CATEGORY_LIST } from "../../service/faqApi";

const CreateFaq = () => {
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		questiontype: "",
        faqcategoryid: ""
	});
	const [faqCategoryList, setFaqCategoryList] = useState([]);
	const [questions, setQuestions] = useState([{ question: "", answer: "" }]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");

	const handleChange = (event) => {
		const { name, value } = event.target;

		if (name === "questiontype" && /^[a-z]/.test(value)) {
			setError(
				"Category name is case sensitive and should start with an uppercase letter."
			);
		} else {
			setError("");
		}

		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleQuestionChange = (index, field, value) => {
		const updatedQuestions = [...questions];
		updatedQuestions[index][field] = value;
		setQuestions(updatedQuestions);
	};

	const addQuestion = () => {
		setQuestions([...questions, { question: "", answer: "" }]);
	};

	const removeQuestion = (index) => {
		const updatedQuestions = questions.filter((_, i) => i !== index);
		setQuestions(updatedQuestions);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setIsLoading(true);
		try {
			const payload = { ...formData, questionanswer: questions };
			const { data } = await CREATE_FAQ(payload);
			const { code, message } = data;

			if (code === 0) {
				toast.error(message);
				setIsLoading(false);
				return;
			}

			toast.success(message);
			navigate("/dashboard/faq-list");
		} catch (error) {
			if (error.response) {
				const { status, data } = error.response;

				if (status === 409) {
					toast.error(data.message || "A conflict occurred. Please try again.");
				} else {
					toast.error(data.message || "An error occurred. Please try again.");
				}
			} else {
				toast.error(error.message || "An error occurred. Please try again.");
			}
			setIsLoading(false);
		}
	};

    const getAllFaqCategoryList = async (page) => {
        try {
            setIsLoading(true)
			const { data } = await FAQ_CATEGORY_LIST({ page });
			setFaqCategoryList(data.result.faqCategories);
		} catch (error) {
			if (error.response) {
				const { data } = error.response;
				toast.error(data.message);
			} else {
				toast.error(error.message || "An error occurred. Please try again.");
			}
		} finally {
			setIsLoading(false); // Stop loading
		}
    }

    useEffect(() => {
        getAllFaqCategoryList()
    }, [])

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-10">
					<div className="form_container mt-4 mb-3">
						<div className="card">
							<div className="card-body">
								<h3 className="card-title text-center">Create FAQ</h3>
								<form onSubmit={handleSubmit}>
									<div className="form-group row">
										<label
											htmlFor="category-name"
											className="col-sm-4 col-form-label"
										>
											Question Type:
										</label>
										<div className="col-sm-8">
											<input
												type="text"
												className="form-control"
												id="category-name"
												name="questiontype"
												placeholder="Enter category name"
												value={formData.questiontype}
												onChange={handleChange}
												required
												disabled={isLoading}
											/>
											{error && <small className="text-danger">{error}</small>}
										</div>
									</div>
                                    <div className="form-group row">
										<label
											htmlFor="category-name"
											className="col-sm-4 col-form-label"
										>
											FAQ Category:
										</label>
										<div className="col-sm-8">
                                        <select
												id="product-type"
												name="faqcategoryid"
												className="form-control form-select"
												value={formData.faqcategoryid}
												onChange={handleChange}
												required
												disabled={isLoading}
											>
												<option value="" disabled selected>
													Select category type
												</option>
												{faqCategoryList.map((option) => (
													<option key={option.value} value={option.id}>
														{option.name}
													</option>
												))}
											</select>
											{error && <small className="text-danger">{error}</small>}
										</div>
									</div>

									{/* Questions and Answers Section */}

									<div className="form-group row">
                                    <label
											htmlFor="category-name"
											className="col-sm-4 col-form-label"
										>
											Question Answer:
										</label>
                                        <div className="col-sm-8">
                                        {questions.map((qa, index) => (
											<div key={index} className="form-group row mt-3">
												<div className="col-sm-5">
													<textarea
														type="text"
														className="form-control"
														placeholder="Enter question"
														value={qa.question}
														onChange={(e) =>
															handleQuestionChange(index, "question", e.target.value)
														}
														disabled={isLoading}
														required
													/>
												</div>
												<div className="col-sm-5">
													<textarea
														type="text"
														className="form-control"
														placeholder="Enter answer"
														value={qa.answer}
														onChange={(e) =>
															handleQuestionChange(index, "answer", e.target.value)
														}
														disabled={isLoading}
														required
													/>
												</div>
												<div className="col-sm-2 text-center">
													<button
														type="button"
														className="btn btn-danger"
														onClick={() => removeQuestion(index)}
														disabled={isLoading}
													>
														-
													</button>
												</div>
											</div>
										))}</div>
									
										<div className="form-group row mt-3">
											<div className="col-sm-12 text-center">
												<button
													type="button"
													className="btn btn-primary"
													onClick={addQuestion}
													disabled={isLoading}
												>
													+ Add Question
												</button>
											</div>
										</div>
									</div>

									<div className="form-group row mt-4">
										<div className="col-sm-4"></div>
										<div className="col-sm-8 button-group">
											<input
												type="submit"
												value={isLoading ? "Submitting..." : "Submit"}
												className="btn btn-success buttonSuccess"
												style={{ width: "150px", marginRight: "10px" }}
												disabled={isLoading}
											/>
											<input
												type="button"
												value="Cancel"
												className="btn btn-secondary "
												style={{ width: "150px" }}
												onClick={() => {
													setFormData({ questiontype: "" });
													setQuestions([{ question: "", answer: "" }]);
												}}
												disabled={isLoading}
											/>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreateFaq;
