import { useNavigate } from "react-router-dom";
import React, { useState, useRef, useMemo, useEffect } from "react";
import QuillImageResize from "quill-image-resize-module-react"; // Import image resize module
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import AWS from "aws-sdk";
import { toast } from "react-toastify";
import { CREATE_BLOG } from "../../service/blogApi";
import ImageUploader from "react-images-upload";

// Set up Quill
const Font = Quill.import("formats/font");
Font.whitelist = ["arial", "courier", "times-new-roman", "verdana"];
Quill.register(Font, true);
Quill.register("modules/imageResize", QuillImageResize);

const CreateBlogs = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [blogImage, setBlogImage] = useState([]);
  const [title, setTitle] = useState("");
  const [images, setImages] = useState([]);
  const [value, setValue] = useState("");
  const quillRef = useRef(null);

  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    region: process.env.REACT_APP_REGION,
  });

  const handleImageUpload = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (!file) return;

      const fileName = `blog-image-${Date.now()}-${file.name}`;
      const params = {
        Bucket: "rentte-images",
        Key: fileName,
        Body: file,
        ContentType: file.type,
      };

      try {
        const data = await s3.upload(params).promise();
        const editor = quillRef.current.getEditor();
        const range = editor.getSelection();
        editor.insertEmbed(range.index, "image", data.Location);
        setValue(editor.root.innerHTML);

        // Update images list
        setImages((prevImages) => [...prevImages, data.Location]);
      } catch (error) {
        alert("Failed to upload image.");
        console.error(error);
      }
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ font: [] }],
          [{ size: ["small", false, "large", "huge"] }],
          ["bold", "italic", "underline", "strike"],
          [{ script: "sub" }, { script: "super" }],
          [{ align: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ color: [] }, { background: [] }],
          ["link", "image"],
          ["code-block"],
          ["clean"],
        ],
        handlers: {
          image: handleImageUpload,
        },
      },
      imageResize: {
        modules: ["Resize", "DisplaySize"],
      },
    }),
    []
  );

  const formats = useMemo(
    () => [
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "strike",
      "script",
      "align",
      "list",
      "indent",
      "color",
      "background",
      "link",
      "image",
      "code-block",
    ],
    []
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const formDataPayload = new FormData();
      formDataPayload.append("title", title);
      formDataPayload.append("content", value);
      blogImage.forEach((image) => {
        formDataPayload.append("blogimage", image);
      });
      const { data } = await CREATE_BLOG(formDataPayload);
      const { code, message } = data;
      if (code === 0) {
        toast.error(message);
        setIsLoading(false);
        return;
      }
      toast.success(message);
      navigate("/dashboard/blog-list");
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        toast.error(data.message || "An error occurred. Please try again.");
      } else {
        toast.error(error.message || "An error occurred. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onDrop = (pictureFiles) => {
    setBlogImage(pictureFiles);
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-10">
          <div className="form_container mt-4 mb-3">
            <div className="career card">
              <div className="card-body">
                <h3 className="card-title text-center">Add Blog</h3>
                {/* Title Input */}
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    placeholder="Enter blog title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>

                <div className="form-group row">
                  <div className="col-sm-8">
                    <ImageUploader
                      withIcon={true}
                      buttonText="Choose Images"
                      onChange={onDrop}
                      imgExtension={[".jpg", ".jpeg", ".png"]}
                      maxFileSize={5242880}
                      withPreview={true}
                    />
                  </div>
                </div>

                {/* Description Input */}

                {/* ReactQuill Editor */}
                <div className="form-group">
                  <label htmlFor="content"></label>
                  <ReactQuill
                    ref={quillRef}
                    theme="snow"
                    value={value}
                    onChange={setValue}
                    placeholder="Write something here..."
                    modules={modules}
                    formats={formats}
                  />
                </div>

                {/* Preview */}
                <p>Preview Content:</p>
                <div dangerouslySetInnerHTML={{ __html: value }} />

                {/* Publish Button */}
                {value && (
                  <div className="text-end">
                    <button
                      className="btn btn-primary mr-5"
                      onClick={handleSubmit}
                    >
                      {isLoading ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBlogs;
