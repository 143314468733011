import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import "./../category/CategoryList.scss";

const EditFaqCategoryModal = ({
	show,
	handleClose,
	faqCategoryData,
	handleSave,
}) => {
	const { REACT_APP_S3_URL } = process.env;
	const [formData, setFormData] = useState({
		name: faqCategoryData.name || "",
		description: faqCategoryData.description || "",
	});
	const [image, setImage] = useState(null);
	const [imagePreview, setImagePreview] = useState(
		faqCategoryData.icon || ""
	);
	const [isLoading, setIsLoading] = useState(false); // Loading state
	const [errors, setErrors] = useState({}); // Validation errors

	useEffect(() => {
		// Update form data and image preview when faqCategoryData changes
		setFormData({
			name: faqCategoryData.name || "",
			description: faqCategoryData.description || "",
		});
		setImagePreview(REACT_APP_S3_URL + faqCategoryData.icon || "");
	}, []);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setImage(file);
			setImagePreview(URL.createObjectURL(file)); // Show preview of the selected image
		}
	};

	const handleSubmit = async () => {
		const newErrors = {};
		let hasErrors = false;

		// Validate fields
		if (!formData.name.trim()) {
			newErrors.name = "Subcategory Name is required";
			hasErrors = true;
		}
		if (!formData.description.trim()) {
			newErrors.description = "Description is required";
			hasErrors = true;
		}

		if (hasErrors) {
			setErrors(newErrors);
			return;
		}

		setIsLoading(true); // Start loading
		const updateFaqCategory = {
			...faqCategoryData,
			...formData,
		};

		if (image) {
			updateFaqCategory.icon = image; // Include the new image if it's selected
		}

		try {
			await handleSave(updateFaqCategory);
		} finally {
			setIsLoading(false); // Stop loading regardless of success or failure
		}
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Edit Category FAQ</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group controlId="formname">
						<Form.Label>Category Name</Form.Label>
						<Form.Control
							type="text"
							name="name"
							value={formData.name}
							onChange={handleChange}
							placeholder="Enter category name"
							disabled={isLoading}
							isInvalid={!!errors.name} // Bootstrap form control invalid styling
						/>
						<Form.Control.Feedback type="invalid">
							{errors.name}
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group controlId="formDescription" className="mt-3">
						<Form.Label>Description</Form.Label>
						<Form.Control
							as="textarea"
							rows={3}
							name="description"
							value={formData.description}
							onChange={handleChange}
							placeholder="Enter description"
							disabled={isLoading}
							isInvalid={!!errors.description} // Bootstrap form control invalid styling
						/>
						<Form.Control.Feedback type="invalid">
							{errors.description}
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group controlId="formImage" className="mt-3">
						<Form.Label>Upload Image</Form.Label>
						<Form.Control
							type="file"
							onChange={handleImageChange}
							accept="image/*"
							disabled={isLoading}
						/>
					</Form.Group>
					{imagePreview && (
						<div className="mt-3 text-center">
							<img
								src={imagePreview}
								alt="Selected"
								style={{
									width: "50px",
									height: "50px",
									objectFit: "contain",
								}}
							/>
						</div>
					)}
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose} disabled={isLoading}>
					Close
				</Button>
				<Button
					variant="primary"
					onClick={handleSubmit}
					className={`btnPrimary ${isLoading ? "loading" : ""}`}
					disabled={isLoading}
				>
					{isLoading ? (
						<>
							<Spinner
								as="span"
								animation="border"
								size="sm"
								role="status"
								aria-hidden="true"
							/>{" "}
							Saving...
						</>
					) : (
						"Save Changes"
					)}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default EditFaqCategoryModal;
