import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GET_ALL_CUSTOMER_QUERY } from "../../service/faqApi";
import ReplyQueriesModal from "./ReplyQueriesModal";
const CustomerQueries = () => {
	const [queryList, setCustomerQueries] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [loading, setLoading] = useState(false); // Add loading state
	const [showModal, setShowModal] = useState(false);
	const [selectedQuery, setSelectedQuery] = useState(null);
	const getCategoryList = async (page) => {
		setLoading(true); // Start loading
		try {
			const { data } = await GET_ALL_CUSTOMER_QUERY({ page });
			setCustomerQueries(data.result.customerQueries);
			setTotalPages(Number(data.result.pagination.totalPages));
		} catch (error) {
			if (error.response) {
				const { data } = error.response;
				toast.error(data.message);
			} else {
				toast.error(error.message || "An error occurred. Please try again.");
			}
		} finally {
			setLoading(false); // End loading
		}
	};

	useEffect(() => {
		getCategoryList(currentPage);
	}, [currentPage]);

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const handleShowModal = (query) => {
		setSelectedQuery(query);
		setShowModal(true);
	};
	const handleCloseModal = () => {
		setShowModal(false);
		setSelectedQuery(null);
	};

	return (
		<div className="container p-4">
			<table className="table table-bordered table-striped table-hover">
				<thead>
					<tr>
						<th scope="col">#</th> {/* Serial Number Column */}
						<th scope="col">Name</th>
						<th scope="col">Email</th>
						<th scope="col">Query Type</th>
            <th scope="col">Message</th>
            <th scope="col">Action</th>
					</tr>
				</thead>
				<tbody>
					{loading ? (
						<tr>
							<td colSpan="4" className="text-center">
								Loading...
							</td>
						</tr>
					) : queryList?.length > 0 ? (
						queryList?.map((data, index) => (
							<tr key={data.id}>
								<td>{(currentPage - 1) * queryList.length + (index + 1)}</td>{" "}
								{/* Calculate Serial Number */}
								<td>{data.name}</td>
								<td>{data.email}</td>
								<td>{data.querytype}</td>
								<td>{data.message}</td>
								<td><button
										className="btn btn-primary btnPrimary btn-sm"
										style={{ marginRight: "5px" }}
										onClick={() => handleShowModal(data)}
									>
										Reply
									</button></td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="4" className="text-center">
								No Queries found.
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{/* Centered Pagination */}
			{totalPages > 1 && (
				<nav>
					<ul className="pagination justify-content-center">
						{[...Array(totalPages).keys()].map((page) => (
							<li
								className={`page-item ${
									page + 1 === currentPage ? "active" : ""
								}`}
								key={page}
							>
								<button
									className="page-link"
									onClick={() => handlePageChange(page + 1)}
								>
									{page + 1}
								</button>
							</li>
						))}
					</ul>
				</nav>
			)}
			{
				selectedQuery && (
					<ReplyQueriesModal
						show={showModal}
						handleClose={handleCloseModal}
						queryData={selectedQuery}
					/>
				)
			}
		</div>
	);
};

export default CustomerQueries;
