import { CONSTANT_URL } from "./apiEndPoint";
import { blogServiceApiCall } from "./blogService";

export const CREATE_BLOG = async (payload) => {
    return await blogServiceApiCall('post',CONSTANT_URL.CREATE_BLOG,payload)
}

export const UPDATE_BLOG = async (payload) => {
    return await blogServiceApiCall('post',CONSTANT_URL.UPDATE_BLOG,payload)
}
export const BLOG_LIST = async (payload) => {
    const page=`?page=${payload.page}`
    return await blogServiceApiCall("get",CONSTANT_URL.BLOG_LIST+ page)
}

export const CREATE_BLOG_CONTENT = async (payload) => {
    return await blogServiceApiCall('post', CONSTANT_URL.CREATE_BLOG_CONTENT, payload)
}

export const DELETE_BLOG = async (payload) => {
    return await blogServiceApiCall("delete", CONSTANT_URL.DELETE_BLOG,payload)
}

export const BLOG_CONTENT_LIST = async (payload) => {
    const page=`?page=${payload.page}`
    return await blogServiceApiCall("get",CONSTANT_URL.BLOG_CONTENT_LIST+ page)
}

export const BLOG_CONTENT_DETAIL = async (payload) => {
    return await blogServiceApiCall("get", CONSTANT_URL.BLOG_DETAIL + `?blogid=${payload.blogId}`)
}

export const CREATE_CAREER = async (payload) => {
    return await blogServiceApiCall('post',CONSTANT_URL.CREATE_CAREER,payload)
}
export const UPDATE_CAREER = async (payload) => {
    return await blogServiceApiCall('post',CONSTANT_URL.UPDATE_CAREER,payload)
}
export const CAREER_LIST = async (payload) => {
    const page=`?page=${payload.page}`
    return await blogServiceApiCall("get",CONSTANT_URL.CAREER_LIST+ page)
}
export const DELETE_CAREER = async (payload) => {
    return await blogServiceApiCall("delete", CONSTANT_URL.DELETE_CAREER,payload)
}
export const CAREER_DETAIL = async (payload) => {
    return await blogServiceApiCall("get", CONSTANT_URL.CAREER_DETAIL + `?id=${Number(payload.id)}`)
}