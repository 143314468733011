
export const CONSTANT_URL = {
    LOGIN: "/admin/auth/login",
    FORGET_PASSWORD: "/admin/auth/forget-password",
    RESET_PASSWORD: "/admin/auth/reset-password",
    BLOCK_UNBLOCK_USER: "admin/user/block-and-unblock-user",
    CREATE_CATEGORY: "/admin/product/create-category",
    CATEGORY_LIST: "/admin/product/category-list",
    DELETE_CATEGORY: "/admin/product/delete-category",
    UPDATE_CATEGORY: "/admin/product/update-category",
    CREATE_SUBCATEGORY: "/admin/product/create-subcategory",
    DELETE_SUBCATEGORY: "/admin/product/delete-subcategory",
    UPDATE_SUBCATEGORY: "/admin/product/update-subcategory",
    SUBCATEGORY_LIST: "/admin/product/subcategory-list",
    USER_LIST: "/user/auth/user-list",
    PARTNER_LIST: "/user/partner/partner-list",
    SEND_OTP: "/admin/auth/send-otp",
    VERIFY_OTP: "/admin/auth/verify-otp",
    CHANGE_PASSWORD: "admin/auth/change-password",
    CREATE_PRODUCT: "/partner/product/create-product",
    GET_SUBCATEGORY_BY_CATEGORY: "/user/product/subcategory-list",
    GET_PRODUCT_LIST: "/admin/product/get-all-product",
    UPDATE_PRODUCT: "/partner/product/edit-product",
    DELETE_PRODUCT: "/partner/product/delete-product",
    BULK_EMAIL: "/notification/email/bulk-email",
    SEND_BULK_EMAIL: "/notification/email/send-email-notification",
    CREATE_BLOG: "/blogs/create-blog",
    UPDATE_BLOG: "/blogs/update-blog",
    BLOG_LIST: "/blogs/blog-list",
    BLOG_DETAIL: "/blogs/blog-detail",
    DELETE_BLOG: "/blogs/delete-blog",
    CREATE_CAREER: "/blogs/create-career",
    UPDATE_CAREER: "/blogs/update-career",
    CAREER_LIST: "/blogs/career-list",
    CAREER_DETAIL: "/blogs/career-detail",
    DELETE_CAREER: "/blogs/delete-career",
    CREATE_FAQ_CATEGORY: "/admin/faqs/create-faq-category",
    UPDATE_FAQ_CATEGORY: "/admin/faqs/edit-faq-category",
    DELETE_FAQ_CATEGORY: "/admin/faqs/delete-faq-category",
    GET_ALL_CUSTOMER_QUERY: "/admin/faqs/get-all-customer-query",
    CREATE_FAQ: "/admin/faqs/create-faq",
    UPDATE_FAQ: "/admin/faqs/edit-faq",
    DELETE_FAQ: "/admin/faqs/delete-faq",
    FAQ_LIST: "/faqs/faq-list",
    FAQ_CATEGORY_LIST: "/user/faqs/faq-category-list",
    FAQ_DETAILS: "/user/faqs/get-faq-details",
    GET_ALL_JOB_APPLICATIONS: "/admin/jobs/get-all-jobapplications",
    REPLY_TO_CUTOMER_QUERY: "/notification/email/reply-to-customer"
}