import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GET_ALL_JOB_APPLICATIONS } from "../../service/jobsApi";
import moment from "moment-timezone";
import { FaFilePdf } from "react-icons/fa6";
const JobApplications = () => {
	const [JobApplications, setJobApplications] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [loading, setLoading] = useState(false); // Add loading stat

	const getJobApplicationList = async (page) => {
		setLoading(true); // Start loading
		try {
			const { data } = await GET_ALL_JOB_APPLICATIONS({ page });
			setJobApplications(data.result.jobApplications);
			setTotalPages(Number(data.result.pagination.totalPages));
		} catch (error) {
			if (error.response) {
				const { data } = error.response;
				toast.error(data.message);
			} else {
				toast.error(error.message || "An error occurred. Please try again.");
			}
		} finally {
			setLoading(false); // End loading
		}
	};

	useEffect(() => {
		getJobApplicationList(currentPage);
	}, [currentPage]);

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	return (
		<div className="container p-4">
			<table className="table table-bordered table-striped table-hover">
				<thead>
					<tr>
						<th scope="col">#</th> {/* Serial Number Column */}
						<th scope="col">Name</th>
						<th scope="col">Email</th>
						<th scope="col">Phone</th>
						<th scope="col">Job Type</th>
						<th scope="col">Experience</th>
						<th scope="col">DOB</th>
						<th scope="col">Resume</th>
					</tr>
				</thead>
				<tbody>
					{loading ? (
						<tr>
							<td colSpan="4" className="text-center">
								Loading...
							</td>
						</tr>
					) : JobApplications?.length > 0 ? (
						JobApplications?.map((user, index) => (
							<tr key={user.id}>
								<td>{(currentPage - 1) * JobApplications.length + (index + 1)}</td>{" "}
								{/* Calculate Serial Number */}
								<td>{user.name}</td>
								<td>{user.email}</td>
								<td>{user.phone}</td>
								<td>{user.jobtype}</td>
								<td>{user.experience}</td>
								<td>{moment(user.dob).tz('UTC').format('DD MMM YYYY')}</td>
                                <td><a href={process.env.REACT_APP_S3_URL + user.resume} style={{textDecoration: 'none', color: '#d32f2f'}} target="_blank" rel="noopener noreferrer"><FaFilePdf /></a></td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="4" className="text-center">
								No categories found.
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{/* Centered Pagination */}
			{totalPages > 1 && (
				<nav>
					<ul className="pagination justify-content-center">
						{[...Array(totalPages).keys()].map((page) => (
							<li
								className={`page-item ${
									page + 1 === currentPage ? "active" : ""
								}`}
								key={page}
							>
								<button
									className="page-link"
									onClick={() => handlePageChange(page + 1)}
								>
									{page + 1}
								</button>
							</li>
						))}
					</ul>
				</nav>
			)}

		</div>
	);
};

export default JobApplications;
