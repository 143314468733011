import { CONSTANT_URL } from "./apiEndPoint";
import { adminServiceApiCall } from "./adminService";

export const LOGIN_ADMIN = async(payload) => {
    return await adminServiceApiCall("post",CONSTANT_URL.LOGIN,payload)
}

export const RESET_PASSWORD = async(payload) => {
    return await adminServiceApiCall("post",CONSTANT_URL.RESET_PASSWORD,payload)
}

export const FORGET_PASSWORD = async (payload) => {
    return await adminServiceApiCall("post",CONSTANT_URL.FORGET_PASSWORD,payload)
}

export const SEND_OTP = async (payload) => {
    return await adminServiceApiCall("post",CONSTANT_URL.SEND_OTP,payload)
}

export const VERIFY_OTP = async (payload) => {
    return await adminServiceApiCall("post",CONSTANT_URL.VERIFY_OTP,payload)
}

export const CHANGE_PASSWORD = async (payload) => {
    return await adminServiceApiCall("post",CONSTANT_URL.CHANGE_PASSWORD,payload)
}

export const BLOCK_UNBLOCK_USER = async (payload) => {
    return await adminServiceApiCall("post",CONSTANT_URL.BLOCK_UNBLOCK_USER,payload)
}
